import {UIComponent} from "@webfruits/core";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {gsap} from "gsap";
import {Signal} from "@webfruits/core/dist/signal/Signal";

/******************************************************************
 * PrevButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PrevButton extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _svg: SVGComponent;
    private _svgIcon: SVGElement;

    public onClickSignal = new Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("close-button");
        this.initSVG();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            cursor: "pointer",
            webkitTapHighlightColor: "transparent"
        })
        gsap.set(this._svgIcon, {
            transformOrigin: "50% 50%"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSVG() {
        this._svg = new SVGComponent(require("/src/assets/icons/prev-button-icon.svg"));
        this._svgIcon = this._svg.getElementByID("icon");
        this.addChild(this._svg);
    }

    private initListeners() {
        this._svg.addNativeListener("click", (e: MouseEvent) => this.onClicked(e));
        this._svg.addNativeListener("mouseenter", () => this.onEntered());
        this._svg.addNativeListener("mouseleave", () => this.onLeft());
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.onClickSignal.dispatch();
    }

    private onEntered() {
        gsap.to(this._svg.transform, {
            duration: 0.2,
            scale: 1.02,
            ease: "power4.out"
        })
        gsap.to(this._svgIcon, {
            duration: 0.3,
            x: -3,
            ease: "power4.out"
        })
    }

    private onLeft() {
        gsap.to(this._svg.transform, {
            duration: 0.2,
            scale: 1,
            ease: "power4.out"
        })
        gsap.to(this._svgIcon, {
            duration: 0.2,
            x: 0,
            ease: "power4.out"
        })
    }

}
