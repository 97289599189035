import {ControllerFactory} from "./controller/ControllerFactory";
import {SplitText} from "gsap/SplitText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {MotionPathPlugin} from "gsap/MotionPathPlugin";
import {CustomEase} from "gsap/CustomEase";
import {gsap} from "gsap";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {CoreState} from "./core/CoreState";
import {AppConfig} from "./config/AppConfig";
import {VideoSystem} from "./systems/video/VideoSystem";
import {ImageSystem} from "./systems/image/ImageSystem";

/******************************************************************
 * TheNorthDrift
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class TheNorthDrift {

    /******************************************************************
     * Properties
     *****************************************************************/

    private static _videoSystem: VideoSystem;
    private static _imageSystem: ImageSystem;

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    static init() {
        this.requireOGImage();
        this.initCore();
        this.checkPreloading();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private static requireOGImage() {
        // og images need absolute path in meta tag og:image,
        // so it has to be required elsewhere (here ;) to be deployed to the dist folder
        require("/src/content/og/the-north-drift-og.jpg").default
    }

    private static initCore() {
        gsap.registerPlugin(SplitText, ScrollTrigger, ScrollToPlugin, CustomEase, MotionPathPlugin);
        this.initSystems();
        ControllerFactory.createComponentControllers();
    }

    private static async checkPreloading() {
        await PromisedDelay.waitUntilValid(() => document.readyState == "complete");
        window.dispatchEvent(new Event("resize"));
        CoreState.IS_PRELOAD_DONE.setValue(true);
        this.removePreloader();
    }

    private static initSystems() {
        this._imageSystem = new ImageSystem();
        this._videoSystem = new VideoSystem();
        document.body.appendChild(this._videoSystem.view);
        document.body.appendChild(this._imageSystem.view);
    }

    private static removePreloader() {
        const preloader = document.getElementsByClassName("preloader")[0] as HTMLElement;
        CoreState.IS_PRELOAD_DONE.setValue(true);
        gsap.to(preloader, {
            duration: 0.75,
            alpha: 0,
            delay: AppConfig.REMOVE_PRELOADED_DELAY,
            scale: 1.1,
            ease: "power4.out",
            onComplete: () => {
                document.documentElement.className = "ready";
                preloader.remove();
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
