import {UIComponent} from "@webfruits/core";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {gsap} from "gsap";
import {Color} from "../style/Color";
import {Signal} from "@webfruits/core/dist/signal/Signal";
import {Size} from "../style/Size";

/******************************************************************
 * WatchButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class WatchButton extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _button: SVGComponent;
    private _buttonFrame: SVGElement;
    private _buttonLabel: SVGElement;
    private _buttonIcon: SVGElement;

    public onClickSignal = new Signal()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _svgXML: string) {
        super("watch-button")
        this.initSVG()
        this.initListeners()
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this._button.applyStyle({
            pointerEvents: "all",
            cursor: "pointer",
            display: "inline-block",
            position: "relative",
            // marginTop: Size.calcResponsiveValue(30, 50, 100),
            // left: "50%",
            // x: "-50%",
            // transformOrigin: "50% 50%",
            scale: Size.calcResponsiveValue(0.8, 1, 1.2)
        })
        gsap.set(this._buttonFrame, {
            transformOrigin: "50% 50%"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSVG() {
        this._button = new SVGComponent(this._svgXML);
        this._buttonFrame = this._button.getElementByID("frame");
        this._buttonLabel = this._button.getElementByID("copy");
        this._buttonIcon = this._button.getElementByID("icon");
        this.addChild(this._button);
    }

    private initListeners() {
        this._button.addNativeListener("mouseenter", () => this.onButtonEntered());
        this._button.addNativeListener("mouseleave", () => this.onButtonLeft());
        this._button.addNativeListener("click", () => this.onButtonClicked());
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onButtonEntered() {
        gsap.to(this._buttonLabel, {
            duration: 0.3,
            fill: Color.BLUE,
            ease: "power4.out"
        })
        gsap.to(this._buttonFrame, {
            duration: 0.3,
            scale: 0.95,
            ease: "power4.out"
        })
    }

    private onButtonLeft() {
        gsap.to(this._buttonLabel, {
            duration: 0.3,
            fill: Color.WHITE,
            ease: "power4.out"
        })
        gsap.to(this._buttonFrame, {
            duration: 0.3,
            scale: 1,
            ease: "power4.out"
        })
    }

    private onButtonClicked() {
        this.onClickSignal.dispatch()
    }
}
