import {UIComponent} from "@webfruits/core";
import {Size} from "../../style/Size";
import {gsap} from "gsap";
import {CoreState} from "../../core/CoreState";
import {CloseButton} from "../../elements/CloseButton";
import {DOMUtils} from "../../utils/DOMUtils";
import {SwipeController} from "@webfruits/toolbox/dist/controller/input/SwipeController";
import {NextButton} from "../../elements/NextButton";
import {PrevButton} from "../../elements/PrevButton";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";

/******************************************************************
 * ImageSystem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ImageSystem extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _background: UIComponent;
    private _loadingIndicator: UIComponent;
    private _closeButton: CloseButton;
    private _image: UIComponent<HTMLImageElement>;
    private _swipeController: SwipeController;
    private _isSwiping: boolean;
    private _nextButton: NextButton;
    private _prevButton: PrevButton;
    private _prevNextButtonFrame: UIComponent;
    private _openAnimationState: "from-left" | "from-right" | "from-center" = "from-center";

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("image-viewer");
        this.initBackground();
        this.initLoadingIndicator();
        this.initImage();
        this.initCloseButton();
        this.initPrevNextButtons();
        this.initSwipeController();
        this.initListeners();
        this.close(true);
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.onChangeSignal.add(() => this.onImageViewerSourceChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            width: Size.VIEWPORT_WIDTH,
            height: Size.VIEWPORT_HEIGHT,
            userSelect: "none",
            webkitUserSelect: "none"
        })
        this._background.applyStyle({
            backgroundColor: window.getComputedStyle(document.body).backgroundColor,
            position: "absolute",
            width: "100%",
            height: "100%"
        })
        this._loadingIndicator.applyStyle({
            position: "absolute",
            width: 10,
            height: 3,
            left: Size.VIEWPORT_WIDTH * 0.5,
            top: "50%",
            borderRadius: 10,
            backgroundColor: "white"
        })
        this._image.applyStyle({
            position: "absolute",
            display: "block",
            top: this.calcImageMargin(),
            left: this.calcImageMargin(),
            width: Size.VIEWPORT_WIDTH - this.calcImageMargin() * 2,
            height: Size.VIEWPORT_HEIGHT - this.calcImageMargin() * 2,
            objectFit: "contain",
            objectPosition: "center",
            pointerEvents: "none",
            userSelect: "none",
            webkitUserSelect: "none"
        })
        this._closeButton.applyStyle({
            position: "absolute",
            right: this.calcImageMargin() * 0.25,
            top: this.calcImageMargin() * 0.25,
        })
        this._prevNextButtonFrame.applyStyle({
            position: "absolute",
            left: "50%",
            bottom: 50,
            x: "-50%",
            border: "1px solid rgba(255, 255, 255, 0.8)",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20px",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initBackground() {
        this._background = new UIComponent("image-viewer-background");
        this.addChild(this._background);
    }

    private initLoadingIndicator() {
        this._loadingIndicator = new UIComponent("loading-indicator");
        this.addChild(this._loadingIndicator);
        this.playLoadingIndicatorAnimation();
    }

    private initImage() {
        this._image = new UIComponent<HTMLImageElement>("img");
        this.addChild(this._image);
    }

    private initCloseButton() {
        this._closeButton = new CloseButton();
        this.addChild(this._closeButton);
    }

    private initPrevNextButtons() {
        this._prevNextButtonFrame = new UIComponent("ui-frame");
        this._nextButton = new NextButton();
        this._prevButton = new PrevButton();
        this.addChild(this._prevNextButtonFrame);
        this._prevNextButtonFrame.addChild(this._prevButton);
        this._prevNextButtonFrame.addChild(this._nextButton);
    }

    private initSwipeController() {
        this._swipeController = new SwipeController(document.body);
    }

    private initListeners() {
        window.addEventListener("click", () => this.onWindowClicked());
        this._closeButton.onClickSignal.add(() => this.onCloseButtonClicked());
        this._nextButton.onClickSignal.add(() => this.onNextButtonClicked());
        this._prevButton.onClickSignal.add(() => this.onPrevButtonClicked());
        this._swipeController.onLeftSwipeSignal.add(() => this.onLeftSwiped());
        this._swipeController.onRightSwipeSignal.add(() => this.onRightSwiped());
        this._swipeController.onSwipeStartSignal.add(() => this.onSwipeStarted());
        this._swipeController.onSwipeEndSignal.add(() => this.onSwipeEnded());
    }

    private playLoadingIndicatorAnimation() {
        gsap.to(this._loadingIndicator.transform, {
            duration: 0.5,
            repeat: -1,
            yoyo: true,
            startAt: {
                x: -50,
                scaleX: 0.5
            },
            motionPath: [
                {scaleX: 2},
                {scaleX: 0.5},
            ],
            x: 50,
            ease: "sine.inOut"
        })
    }

    private async open(src: string) {
        if (CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.getPreviousValue()) {
            gsap.to(this._image.view, {
                duration: 0.2,
                alpha: 0,
                x: this.calcOutroX(),
                scale: 0.98,
                ease: "power4.out"
            })
            await PromisedDelay.wait(0.3);
        }
        this._swipeController.enabled = true;
        this._image.view.onload = () => {
            gsap.set(this._image.view, {
                scale: 0.98,
                x: this.calcIntroX()
            })
            gsap.to(this._image.view, {
                duration: 0.5,
                alpha: 1,
                scale: 1,
                x: 0,
                ease: "power4.out"
            })
            gsap.to(this._loadingIndicator.view, {
                duration: 0.2,
                alpha: 0,
                ease: "power4.out"
            })
        }
        this._image.view.src = src;
        this.interactive = true;
        DOMUtils.disablePageScrolling();
        gsap.to(this._background.view, {
            duration: 0.5,
            alpha: 0.95,
            ease: "power4.out"
        })
        gsap.to([this._prevNextButtonFrame.view, this._closeButton.view, this._loadingIndicator.view], {
            duration: 0.5,
            alpha: 1,
            ease: "power4.out"
        })
    }

    private close(instantly: boolean = false) {
        this._swipeController.enabled = false;
        this._openAnimationState = "from-center";
        this.interactive = false;
        DOMUtils.enablePageScrolling();
        this._image.view.onload = null;
        gsap.to([this._prevNextButtonFrame.view, this._image.view, this._closeButton.view, this._background.view, this._loadingIndicator.view], {
            duration: instantly ? 0 : 0.3,
            alpha: 0,
            ease: "power4.out"
        })
    }

    private showPrevImage() {
        this._openAnimationState = "from-left";
        let prevID = this.getCurrentThumbnailID() - 1;
        if (prevID < 0) {
            prevID = CoreState.IMAGE_VIEWER_GALLERY_THUMBNAILS.getValue().length - 1;
        }
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.setValue(this.getThumbnailByID(prevID));
    }

    private showNextImage() {
        this._openAnimationState = "from-right";
        let nextID = this.getCurrentThumbnailID() + 1;
        if (nextID > CoreState.IMAGE_VIEWER_GALLERY_THUMBNAILS.getValue().length - 1) {
            nextID = 0;
        }
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.setValue(this.getThumbnailByID(nextID));
    }

    private getCurrentThumbnailID(): number {
        return this.getThumbnailID(CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.getValue());
    }

    private getThumbnailID(thumbnail: HTMLImageElement): number {
        return CoreState.IMAGE_VIEWER_GALLERY_THUMBNAILS.getValue().indexOf(thumbnail);
    }

    private getThumbnailByID(id: number): HTMLImageElement {
        return CoreState.IMAGE_VIEWER_GALLERY_THUMBNAILS.getValue()[id];
    }

    private calcImageMargin() {
        return Size.calcResponsiveValue(5, 30, 60);
    }

    private calcOutroX(): number {
        switch (this._openAnimationState) {
            case "from-center":
                return 0;
            case "from-left":
                return 100;
            case "from-right":
                return -100;
        }
    }

    private calcIntroX(): number {
        switch (this._openAnimationState) {
            case "from-center":
                return 0;
            case "from-left":
                return -100;
            case "from-right":
                return 100;
        }
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onWindowClicked() {
        if (this._isSwiping) return;
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.setValue(null);
    }

    private onCloseButtonClicked() {
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.setValue(null);
    }

    private onImageViewerSourceChanged() {
        if (CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.hasValue()) {
            this.open(CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.getValue().dataset.hiresSrc)
        } else {
            this.close();
        }
    }

    private onLeftSwiped() {
        this.showNextImage();
    }

    private onRightSwiped() {
        this.showPrevImage();
    }

    private onSwipeStarted() {
        this._isSwiping = true;
    }

    private onSwipeEnded() {
        requestAnimationFrame(() => {
            this._isSwiping = false;
        })
    }

    private onNextButtonClicked() {
        this.showNextImage();
    }

    private onPrevButtonClicked() {
        this.showPrevImage();
    }

}
