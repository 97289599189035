import {AbstractComponentController} from "../AbstractComponentController";
import {ScrollTrigger} from "gsap/ScrollTrigger";

/******************************************************************
 * RoutesController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class RoutesController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _video: HTMLVideoElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initScrollTrigger();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._video = this.getElement("video") as HTMLVideoElement;
    }

    private initScrollTrigger() {
        ScrollTrigger.create({
            trigger: this._video,
            onEnter: () => {
                this._video.currentTime = 0;
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet


}
