import {AbstractComponentController} from "../AbstractComponentController";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {CoreState} from "../../core/CoreState";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {AppConfig} from "../../config/AppConfig";

/******************************************************************
 * NavigationController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class NavigationController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _navAnchors: HTMLAnchorElement[];
    private _scrollBox: HTMLElement;
    private _items: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initListeners();
        this.initStickyNavigation();
        CoreState.IS_PRELOAD_DONE.onChangeSignal.add(() => this.onPreloadStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        gsap.set(this._items, {
            borderLeftWidth: this._items.offsetWidth < this._scrollBox.scrollWidth ? "1" : "0",
            borderRightWidth: this._items.offsetWidth < this._scrollBox.scrollWidth ? "1" : "0"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._navAnchors = this.getElements(".navigation-item") as HTMLAnchorElement[];
        this._scrollBox = this.getElement(".navigation-scrollbox");
        this._items = this.getElement(".navigation-items");
    }

    private initListeners() {
        this._navAnchors.forEach((anchor) => {
            const targetSection = document.getElementById(anchor.href.split("#")[1]);
            anchor.addEventListener("click", (e: MouseEvent) => this.onAnchorClicked(e, targetSection));
        })
    }

    private initStickyNavigation() {
        ScrollTrigger.create({
            trigger: "header",
            start: "100% 100%",
            end: "100% 50%",
            onUpdate: (scrollTrigger) => {
                gsap.to(this._scrollBox, {
                    duration: 0.4,
                    y: scrollTrigger.progress * this.calcScrollBoxBottom() + 4,
                    ease: "power4.out"
                })
            }
        })
    }

    private playIntro() {
        gsap.from(this._items, {
            duration: 1,
            y: 10,
            delay: 0.7,
            alpha: 0,
            ease: "power4.out"
        })
        gsap.from(this._navAnchors, {
            duration: 1,
            stagger: 0.05,
            y: 20,
            delay: 0.8,
            alpha: 0,
            ease: "power4.out"
        })
    }

    private scrollToSection(targetSection: HTMLElement) {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: targetSection,
                offsetY: this._scrollBox.offsetHeight * 2
            },
            ease: "power4.inOut"
        })
    }

    private calcScrollBoxBottom(): number {
        const reducer = 0.5
        return Math.round(parseFloat(window.getComputedStyle(this._scrollBox).bottom) * reducer);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onAnchorClicked(e: MouseEvent, targetSection: HTMLElement) {
        e.preventDefault();
        this.scrollToSection(targetSection);
    }

    private async onPreloadStateChanged() {
        await PromisedDelay.wait(AppConfig.REMOVE_PRELOADED_DELAY);
        this.playIntro();
    }

}
