import '@webcomponents/custom-elements'
import {TheNorthDrift} from "./the-north-drift/TheNorthDrift";

require('../styles/index.scss')

/* ----------------------------------------------------------------
 * Version
 * --------------------------------------------------------------*/

console.log("THE NORTH DRIFT WEBSITE " + process.env.version + (process.env.production ? "" : " (dev-build)"));

/* ----------------------------------------------------------------
 * Initialize the App
 * --------------------------------------------------------------*/

TheNorthDrift.init();
