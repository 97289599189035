import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {AbstractComponentController} from "../AbstractComponentController";

/******************************************************************
 * ScrollEffectController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ScrollEffectController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _isInitializing = true;
    private _numElementsAtSameTop: number;
    private _elementIDWithinSameTopSiblings: number;
    private readonly _elementID: number;
    private readonly _siblings: HTMLElement[];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(element: HTMLElement) {
        super(element);
        if (element.dataset.disableScrollEffect == "true") {
            return;
        }
        this._siblings = Array.from(this._root.view.parentElement.getElementsByClassName(this._root.view.className)) as HTMLElement[];
        this._elementID = DOMUtils.getElementIndex(this._root.view);
        requestAnimationFrame(() => {
            this.updateSiblingsVariables();
            this.initScrollTrigger()
            this._isInitializing = false;
        })

    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/


    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initScrollTrigger() {
        gsap.set(this._root.view, {
            y: 100,
            alpha: 0
        })
        ScrollTrigger.create({
            trigger: this._root.view,
            start: "top top+=95%",
            onEnter: () => {
                this.updateSiblingsVariables();
                gsap.killTweensOf(this._root.view);
                gsap.to(this._root.view, {
                    delay: this.calcDelay(),
                    duration: 1.3,
                    y: 0,
                    alpha: 1,
                    ease: "power4.out"
                })
            },
            onLeaveBack: () => {
                gsap.killTweensOf(this._root.view);
                gsap.to(this._root.view, {
                    duration: 0.5,
                    y: 100,
                    alpha: 0,
                    ease: "power4.in"
                })
            }
        });
    }

    private calcDelay() {
        if (this._isInitializing) {
            if (this._siblings.length <= 1) {
                if (this._root.view.parentElement.className.indexOf("kkt-article-header-content-left") != -1) {
                    switch (this._root.view.tagName.toLowerCase()) {
                        case "h1":
                            return 0;
                        case "h2":
                            return 0.1;
                        case "nav":
                            return 0.2;
                    }
                    return 0;
                }
                if (this._root.view.className.indexOf("kkt-article-header-content-right-abstract") != -1) {
                    return 0.3;
                }
                return 0;
            }
            return this._elementID / this._siblings.length * 0.5;
        }
        if (this._numElementsAtSameTop > 1) {
            return this._elementIDWithinSameTopSiblings / this._numElementsAtSameTop * 0.5;
        }
        return 0;
    }

    private updateSiblingsVariables() {
        this._numElementsAtSameTop = 0;
        let firstSiblingIDAtSameTop: number = undefined;
        const thisTop = this._root.view.offsetTop;
        this._siblings.forEach((sibling: HTMLElement) => {
            const siblingTop = sibling.offsetTop;
            if (siblingTop - 2 < thisTop && siblingTop + 2 > thisTop) {
                if (firstSiblingIDAtSameTop == undefined) {
                    firstSiblingIDAtSameTop = DOMUtils.getElementIndex(sibling);
                }
                this._numElementsAtSameTop++;
            }
        });
        this._elementIDWithinSameTopSiblings = DOMUtils.getElementIndex(this._root.view) - firstSiblingIDAtSameTop;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet

}
