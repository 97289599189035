import {AbstractComponentController} from "../AbstractComponentController";
import {gsap} from "gsap";

/******************************************************************
 * FooterController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FooterController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _metaAnchors: HTMLAnchorElement[];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._metaAnchors = this.getElements(".footer-meta-links > a") as HTMLAnchorElement[];
    }

    private initListeners() {
        this._metaAnchors.forEach((link) => {
            link.addEventListener("click", (e: MouseEvent) => this.onAnchorClicked(e, link.href.split("#")[1]))
        })
    }

    private showSection(targetID: string) {
        const targetElement = document.getElementById(targetID);
        gsap.set(targetElement, {
            alpha: 0
        })
        gsap.to(targetElement, {
            height: "auto",
            duration: 1,
            alpha: 1,
            ease: "power4.out",
            onUpdate: () => {
                gsap.to(window, {
                    duration: 0.5,
                    ease: "power4.out",
                    scrollTo: {
                        y: targetElement
                    }
                });
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onAnchorClicked(e: MouseEvent, targetID: string) {
        e.preventDefault();
        this.showSection(targetID);
    }

}
