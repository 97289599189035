import {UIComponent} from "@webfruits/core";
import {CoreState} from "../../core/CoreState";
import {QueryStylesController} from "@webfruits/toolbox/dist/controller/style/QueryStylesController";
import {Size} from "../../style/Size";
import {gsap} from "gsap";
import {CloseButton} from "../../elements/CloseButton";
import {DOMUtils} from "../../utils/DOMUtils";

/******************************************************************
 * VideoSystem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class VideoSystem extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _videoFrame: UIComponent;
    private _queryStyles: QueryStylesController;
    private _background: UIComponent;
    private _closeButton: CloseButton;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("video-system");
        this.initBackground();
        this.initVideoFrame();
        this.initCloseButton();
        this.initQueryStyles();
        this.initListeners();
        this.close(true);
        CoreState.VIDEO_VIEWER_SRC.onChangeSignal.add(() => this.onVideoViewerSourceChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            width: Size.VIEWPORT_WIDTH,
            height: Size.VIEWPORT_HEIGHT,
            pointerEvents: "none",
            userSelect: "none",
            webkitUserSelect: "none"
        })
        this._background.applyStyle({
            backgroundColor: window.getComputedStyle(document.body).backgroundColor,
            position: "absolute",
            width: "100%",
            height: "100%"
        })
        this._videoFrame.applyStyle({
            position: "absolute",
            display: "block",
            top: this.calcVideoMargin(),
            left: this.calcVideoMargin(),
            width: Size.VIEWPORT_WIDTH - this.calcVideoMargin() * 2,
            height: Size.VIEWPORT_HEIGHT - this.calcVideoMargin() * 2,
        })
        this._closeButton.applyStyle({
            position: "absolute",
            right: this.calcVideoMargin() * 0.25,
            top: this.calcVideoMargin() * 0.25,
        })
        if (this._videoFrame.view.querySelectorAll("iframe")[0]) {
            this._queryStyles.to("iframe", {
                width: "100%",
                height: "100%",
                pointerEvents: "all"
            })
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initBackground() {
        this._background = new UIComponent("video-background");
        this.addChild(this._background);
    }

    private initVideoFrame() {
        this._videoFrame = new UIComponent("video-frame");
        this.addChild(this._videoFrame);
    }

    private initCloseButton() {
        this._closeButton = new CloseButton();
        this.addChild(this._closeButton);
    }

    private initQueryStyles() {
        this._queryStyles = new QueryStylesController(this._videoFrame.view);
    }

    private initListeners() {
        this._background.addNativeListener("click", () => this.onBackgroundClicked());
        this._closeButton.onClickSignal.add(() => this.onCloseButtonClicked());
    }

    private open(videoURL: string) {
        DOMUtils.disablePageScrolling()
        this.interactive = true;
        this._background.interactive = true;
        this._closeButton.interactive = true;
        this._videoFrame.view.innerHTML = `
            <iframe 
                src="${videoURL}" 
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
            </iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
        `;
        this.updateStyles();
        gsap.to([this._videoFrame.view, this._closeButton.view], {
            duration: 0.5,
            alpha: 1,
            ease: "power4.out"
        })
        gsap.to(this._background.view, {
            duration: 0.5,
            alpha: 0.9,
            ease: "power4.out"
        })
    }

    private close(instantly: boolean = false) {
        DOMUtils.enablePageScrolling()
        this.interactive = false;
        this._background.interactive = false;
        this._closeButton.interactive = false;
        gsap.to([this._videoFrame.view, this._closeButton.view, this._background.view], {
            duration: instantly ? 0 : 0.3,
            alpha: 0,
            ease: "power4.out",
            onComplete: () => {
                this._videoFrame.view.innerHTML = "";
            }
        })
    }

    private calcVideoMargin() {
        return Size.calcResponsiveValue(5, 30, 60);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onBackgroundClicked() {
        console.log("onBackgroundClicked");
        CoreState.VIDEO_VIEWER_SRC.setValue(null);
    }

    private onCloseButtonClicked() {
        CoreState.VIDEO_VIEWER_SRC.setValue(null);
    }

    private onVideoViewerSourceChanged() {
        if (CoreState.VIDEO_VIEWER_SRC.hasValue()) {
            this.open(CoreState.VIDEO_VIEWER_SRC.getValue())
        } else {
            this.close()
        }
    }

}
