/******************************************************************
 * Color
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Color {
    static readonly BLUE = "#3B597D";
    static readonly DEEP_BLUE = "#0E2B37";
    static readonly WHITE = "#FFFFFF";
}
