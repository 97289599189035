import {AbstractComponentController} from "../AbstractComponentController";
import {CoreState} from "../../core/CoreState";

/******************************************************************
 * GalleryController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class GalleryController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _thumbnails: HTMLImageElement[];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._thumbnails = this.getElements("img") as HTMLImageElement[];
    }

    private initListeners() {
        this._thumbnails.forEach((thumbnail) => {
            thumbnail.addEventListener("click", (e: MouseEvent) => this.onThumbnailClicked(e, thumbnail));
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onThumbnailClicked(e: MouseEvent, thumbnail: HTMLImageElement) {
        e.preventDefault();
        e.stopPropagation();
        CoreState.IMAGE_VIEWER_GALLERY_THUMBNAILS.setValue(this._thumbnails);
        CoreState.IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL.setValue(thumbnail);
    }

}
