import {Stage2DController} from "./stage/Stage2DController";
import {GalleryController} from "./gallery/GalleryController";
import {FooterController} from "./footer/FooterController";
import {NavigationController} from "./navigation/NavigationController";
import {RoutesController} from "./routes/RoutesController";
import {MainTitleController} from "./title/MainTitleController";
import {ScrollEffectController} from "./effects/ScrollEffectController";
import {IntroEffectController} from "./effects/IntroEffectController";
import {StreamController} from "./stream/StreamController";

/******************************************************************
 * ControllerFactory
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ControllerFactory {

    private static CONTROLLER_DICTIONARY = [
        {selector: ".stage-2d", ControllerClass: Stage2DController},
        {selector: ".gallery", ControllerClass: GalleryController},
        {selector: ".footer", ControllerClass: FooterController},
        {selector: ".navigation", ControllerClass: NavigationController},
        {selector: ".routes", ControllerClass: RoutesController},
        {selector: ".main-title", ControllerClass: MainTitleController},
        {selector: ".stream", ControllerClass: StreamController},

        {selector: ".abstract", ControllerClass: ScrollEffectController},
        {selector: ".festivals-logo", ControllerClass: ScrollEffectController},
        {selector: ".stream > h2", ControllerClass: ScrollEffectController},
        {selector: ".stream > .stream-info", ControllerClass: ScrollEffectController},
        {selector: ".stream > .stream-options > .stream-options-button", ControllerClass: ScrollEffectController},
        {selector: ".stream > .stream-watch-trailer-button-box", ControllerClass: ScrollEffectController},
        {selector: ".stream > .stream-powered-by", ControllerClass: ScrollEffectController},
        {selector: ".stream > .stream-additional-services", ControllerClass: ScrollEffectController},
        {selector: ".content > h2", ControllerClass: ScrollEffectController},
        {selector: ".content > .info", ControllerClass: ScrollEffectController},
        {selector: ".fact-title", ControllerClass: ScrollEffectController},
        {selector: ".fact-desc", ControllerClass: ScrollEffectController},
        {selector: ".fact-source", ControllerClass: ScrollEffectController},
        {selector: ".gallery", ControllerClass: ScrollEffectController},
        {selector: ".dates > h2", ControllerClass: ScrollEffectController},
        {selector: ".dates > ul", ControllerClass: ScrollEffectController},
        {selector: ".dates-link > a", ControllerClass: ScrollEffectController},
        {selector: ".dates-link > p", ControllerClass: ScrollEffectController},
        {selector: ".routes > h2", ControllerClass: ScrollEffectController},
        {selector: ".routes > video", ControllerClass: ScrollEffectController},
        {selector: ".people > h2", ControllerClass: ScrollEffectController},
        {selector: ".people-vitas-vita", ControllerClass: ScrollEffectController},
        {selector: ".take-action > h2", ControllerClass: ScrollEffectController},
        {selector: ".language-switch", ControllerClass: IntroEffectController},
        {selector: ".take-action > .take-action-desc", ControllerClass: ScrollEffectController},
        {selector: ".take-action > .take-action-entry", ControllerClass: ScrollEffectController},
        {selector: ".funds-sponsors-sponsor", ControllerClass: ScrollEffectController},
        {selector: ".newsletter", ControllerClass: ScrollEffectController},
    ]

    static createComponentControllers() {
        ControllerFactory.CONTROLLER_DICTIONARY.forEach(entry => {
            Array.from(document.body.querySelectorAll(entry.selector)).forEach((element: HTMLElement) => {
                new entry.ControllerClass(element);
            });
        });
    }

}
