import {AbstractComponentController} from "../AbstractComponentController";
import {CoreState} from "../../core/CoreState";
import {WatchButton} from "../../elements/WatchButton";

/******************************************************************
 * StreamController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class StreamController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _watchTrailerButtonBox: HTMLElement;
    private _watchTrailerButton: WatchButton;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initWatchTrailerBox();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._watchTrailerButtonBox = this.getElement(".stream-watch-trailer-button-box");
    }

    private initWatchTrailerBox() {
        this._watchTrailerButton = new WatchButton(require("/src/assets/ui/watch-trailer-button.svg"))
        this._watchTrailerButton.onClickSignal.add(() => {
            CoreState.VIDEO_VIEWER_SRC.setValue(this._root.view.dataset.trailerUrl);
        })
        this._watchTrailerButtonBox.appendChild(this._watchTrailerButton.view);
    }

    /******************************************************************
     * Events
     *****************************************************************/

}
