import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";

/******************************************************************
 * CoreState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreState {

    static readonly IS_PRELOAD_DONE = new StateValue<boolean>(false);
    static readonly IMAGE_VIEWER_GALLERY_THUMBNAILS = new StateValue<HTMLImageElement[]>(null);
    static readonly IMAGE_VIEWER_GALLERY_SELECTED_THUMBNAIL = new StateValue<HTMLImageElement>(null);
    static readonly VIDEO_VIEWER_SRC = new StateValue<string>(null);
}
