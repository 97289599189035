import {AbstractComponentController} from "../AbstractComponentController";
import {CoreState} from "../../core/CoreState";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";

/******************************************************************
 * IntroEffectController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class IntroEffectController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    // no properties yet

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        CoreState.IS_PRELOAD_DONE.onChangeSignal.add(() => this.initEffect());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initEffect() {
        gsap.set(this._root.view, {
            alpha: 0,
            y: 50
        })
        ScrollTrigger.create({
            trigger: this._root.view,
            start: "10% 100%",
            onEnter: () => {
                gsap.to(this._root.view, {
                    duration: 0.5,
                    alpha: 1,
                    y: 0,
                    ease: "power4.out"
                })
            },
            onLeaveBack: () => {
                gsap.to(this._root.view, {
                    duration: 0.3,
                    alpha: 0,
                    y: 50,
                    ease: "power1.in"
                })
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet

}
