import {AbstractComponentController} from "../AbstractComponentController";
import {CoreState} from "../../core/CoreState";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {AppConfig} from "../../config/AppConfig";
import {gsap} from "gsap";
import {WatchButton} from "../../elements/WatchButton";

/******************************************************************
 * MainTitleController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MainTitleController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _title: HTMLElement;
    private _subTitle: HTMLElement;
    private _mainTitleBox: HTMLElement;
    private _watchTrailerButton: WatchButton;
    private _watchStreamButton: WatchButton;
    private _mainTitleButtons: HTMLElement;
    private _scrollBox: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initWatchButtons();
        CoreState.IS_PRELOAD_DONE.onChangeSignal.add(() => this.onPreloadStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._title = this.getElement("h1");
        this._subTitle = this.getElement("h3");
        this._mainTitleBox = this.getElement(".main-title-box");
        this._mainTitleButtons = this.getElement(".main-title-box-buttons");
        this._scrollBox = document.getElementsByClassName("navigation-scrollbox")[0] as HTMLElement;
    }

    private initWatchButtons() {
        this._watchTrailerButton = new WatchButton(require("/src/assets/ui/watch-trailer-button.svg"))
        this._watchTrailerButton.onClickSignal.add(() => {
            CoreState.VIDEO_VIEWER_SRC.setValue(this._root.view.dataset.trailerUrl);
        })
        this._watchStreamButton = new WatchButton(require("/src/assets/ui/watch-stream-button.svg"))
        this._watchStreamButton.onClickSignal.add(() => {
            this.scrollToStreamSections()
        })
        this._mainTitleButtons.appendChild(this._watchTrailerButton.view);
        this._mainTitleButtons.appendChild(this._watchStreamButton.view);
    }

    private scrollToStreamSections() {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: "#stream",
                offsetY: this._scrollBox.offsetHeight * 2
            },
            ease: "power4.inOut"
        })
    }

    private playIntro() {
        gsap.from(this._title, {
            duration: 1,
            alpha: 0,
            y: 20,
            delay: 0.3,
            ease: "power4.out"
        })
        gsap.from(this._subTitle, {
            duration: 1,
            delay: 0.35,
            y: 40,
            alpha: 0,
            ease: "power4.out"
        })
        gsap.from([this._watchTrailerButton.view, this._watchStreamButton.view], {
            stagger: 0.1,
            duration: 1,
            alpha: 0,
            y: 40,
            delay: 0.5,
            ease: "power4.out"
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private async onPreloadStateChanged() {
        await PromisedDelay.wait(AppConfig.REMOVE_PRELOADED_DELAY);
        this.playIntro();
    }


}
