import {MathUtils} from "@webfruits/toolbox/dist/utils/MathUtils";

/******************************************************************
 * Size
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Size {

    /* ----------------------------------------------------------------
 	 * APP
 	 * --------------------------------------------------------------*/

    static get VIEWPORT_WIDTH(): number {
        return window.innerWidth
    }

    static get VIEWPORT_HEIGHT(): number {
        return window.innerHeight
    }

    /* ----------------------------------------------------------------
 	 * STAGE
 	 * --------------------------------------------------------------*/

    static get STAGE_3D_WIDTH() {
        return (document.getElementsByClassName("stage-3d")[0] as HTMLElement).offsetWidth;
    }

    static get STAGE_3D_HEIGHT() {
        return (document.getElementsByClassName("stage-3d")[0] as HTMLElement).offsetHeight;
    }

    /* ----------------------------------------------------------------
 	 * RESPONSIVE HELPERS
 	 * --------------------------------------------------------------*/

    static readonly MIN_MOBILE_WIDTH_BREAKPOINT: number = 320;
    static readonly MAX_MOBILE_WIDTH_BREAKPOINT: number = 767;
    static readonly MAX_DESKTOP_WIDTH_BREAKPOINT: number = 1920;

    static useMobileStyle(): boolean {
        return Size.VIEWPORT_WIDTH < Size.MAX_MOBILE_WIDTH_BREAKPOINT;
    }

    static calcWidthBreakpointValue(minValue: number, maxValue: number, minBreakPoint: number = Size.MIN_MOBILE_WIDTH_BREAKPOINT, maxBreakPoint: number = Size.MAX_MOBILE_WIDTH_BREAKPOINT): number {
        const progress = MathUtils.calcProgressWithinValues(minBreakPoint, maxBreakPoint, Size.VIEWPORT_WIDTH)
        return progress * (maxValue - minValue) + minValue;
    }

    static calcResponsiveValue(minMobile: number, maxMobile: number, maxDesktop: number): number {
        if (this.useMobileStyle()) {
            return this.calcWidthBreakpointValue(minMobile, maxMobile, Size.MIN_MOBILE_WIDTH_BREAKPOINT, Size.MAX_MOBILE_WIDTH_BREAKPOINT);
        }
        return this.calcWidthBreakpointValue(maxMobile, maxDesktop, Size.MAX_MOBILE_WIDTH_BREAKPOINT, Size.MAX_DESKTOP_WIDTH_BREAKPOINT);
    }
}
