import {AbstractComponentController} from "../AbstractComponentController";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";
import {CoreState} from "../../core/CoreState";
import {Size} from "../../style/Size";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {AppConfig} from "../../config/AppConfig";

/******************************************************************
 * Stage2DController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Stage2DController extends AbstractComponentController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _scrollTrigger: ScrollTrigger;
    private _imageFrontLayer: HTMLElement;
    private _imageBackLayer: HTMLElement;
    private _imageMidLayer: HTMLElement;
    private _images: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        CoreState.IS_PRELOAD_DONE.onChangeSignal.add(() => this.onPreloadStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.updateImageStyles();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._images = this.getElement(".stage-2d-images");
        this._imageFrontLayer = this.getElement(".stage-2d-z1");
        this._imageMidLayer = this.getElement(".stage-2d-z2");
        this._imageBackLayer = this.getElement(".stage-2d-z3");
    }

    public initScrollTrigger() {
        this._scrollTrigger = ScrollTrigger.create({
            trigger: document.documentElement,
            start: "0% 0%",
            end: "100% 100%",
            onUpdate: () => {
                this.updateImageStyles()
            }
        })
    }

    private playIntro() {
        gsap.from(this._root.view, {
            duration: 1.5,
            alpha: 0,
            y: 100,
            scale: 1.1,
            ease: "power4.out"
        })
    }

    private calcImageAlpha() {
        return this.calcWindowHeightScrollProgress(true) * 0.8 + 0.2;
    }

    private calcImageY(multiplier: number) {
        const responsiveOffsetY = Size.calcResponsiveValue(0, 0, 450);
        const progress = (1 - this.calcWindowHeightScrollProgress()) * multiplier;
        return progress * window.innerHeight + responsiveOffsetY;
    }

    private updateImageStyles() {
        gsap.set(this._images, {
            alpha: this.calcImageAlpha()
        })
        gsap.set(this._imageFrontLayer, {
            y: -this.calcImageY(0.6)
        })
        gsap.set(this._imageMidLayer, {
            y: -this.calcImageY(0.54)
        })
        gsap.set(this._imageBackLayer, {
            y: -this.calcImageY(0.4)
        })
    }

    private calcWindowHeightScrollProgress(limitProgress: boolean = false) {
        let scrollProgress = (window.innerHeight - window.scrollY) / window.innerHeight;
        if (limitProgress) {
            if (scrollProgress > 1) scrollProgress = 1;
            if (scrollProgress < 0) scrollProgress = 0;
        }
        return scrollProgress;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private async onPreloadStateChanged() {
        this.initScrollTrigger();
        await PromisedDelay.wait(AppConfig.REMOVE_PRELOADED_DELAY);
        this.playIntro();
    }

}
