/******************************************************************
 * DOMUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DOMUtils {

    static disablePageScrolling() {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden"
    }

    static enablePageScrolling() {
        document.body.style.overflow = null;
        document.documentElement.style.overflow = null
    }

}
